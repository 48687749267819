/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";

.router-wrapper  {
    position:  relative;
    overflow:  hidden;
    width:  100vw;
    height:  calc(100%  -  47px);
    perspective:  1200px;
    transform-style:  preserve-3d;
}

:host  {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #60d7a9;
}

.sp-policy-erp{
    font-size:14px !important;
}

.small-policy-erp{
    font-size: 11px;
}

.invalid-feedback{
    font-size: 11px;
}

 .custom-card-erp{
     margin-bottom: 30px;
     display: flex;
     flex-direction: column;
 }

@media screen and (max-width: 800px) {
    .desktop-hidden {
        display: initial;
    }
    .mobile-hidden {
        display: none;
    }
}
@media screen and (min-width: 800px) {
    .desktop-hidden {
        display: none;
    }
    .mobile-hidden {
        display: initial;
    }
}

.modal-header {
    background: #C3C4D9 !important;
}

.modal-title {
    color: white !important;
}

label {
    font-weight: bold;
}

.btn-close {
    background-color: white !important;
}

.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary.active {
    background-color: #1e499b !important;
    border-color: #1e499b !important;
}

.fw-bold{
    font-weight: bold !important;
}

* {
    color-adjust: exact;
    -webkit-print-color-adjust: exact;
    print-color-adjust:exact;
}

// Form

/*.card .card-header {
    // background-color: #f2f6ff;
    padding: 15px;
    border-bottom: 2px solid #ecf3fa;
}

.card .card-body {
    padding: 15px;
    // background-color: #C3C4D9;
}

.card .card-footer {
    // background-color: #f2f6ff;
    border-top: 1px solid #ecf3fa;
    padding: 15px;
}*/

.card .card-header {
    background-color: var(--light-background);
}

h5, .h5 {
    font-size: 1rem;
}

// Sidebar
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-wrapper, .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-icon-wrapper {
    padding: 0 5px;
    // box-shadow: -9px 0 20px rgba(89, 102, 122, 0.1);
    border: 1px solid black;
}

.toggle-sidebar {
    // right: 30px;
    top: 20px;
}

.toggle-sidebar svg {
    width: 15px;
    height: 15px;
}


// Header
/*.page-wrapper .page-header .header-wrapper {
    padding: 12px 20px;
    border: 1px solid black;
}*/

/*.page-wrapper .page-body-wrapper .page-title {
    padding: 9px 30px;
    margin: -20px -27px 30px;
    background-color: #fff;
    border: 1px solid black;
}*/

